<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item prop="triggetId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('触发器id')}}<template slot="title">{{$t('触发器id')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.triggetId"  :placeholder="$t('通用.输入.请输入')+$t('触发器id')" />
      </a-form-model-item>
      <a-form-model-item prop="triggerName" >
        <span slot="label" >
          <a-tooltip>
            {{$t('触发器名称')}}<template slot="title">{{$t('触发器名称')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.triggerName" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('触发器名称')" />
      </a-form-model-item>
      <a-form-model-item prop="projectId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('项目id')}}<template slot="title">{{$t('项目id')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.projectId"  :placeholder="$t('通用.输入.请输入')+$t('项目id')" />
      </a-form-model-item>
      <a-form-model-item prop="triggerType" >
        <span slot="label" >
          <a-tooltip>
            {{$t('触发器类型(独立触发器 1,模板触发器 2)')}}<template slot="title">{{$t('触发器类型(独立触发器 1,模板触发器 2)')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="deviceId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('设备id')}}<template slot="title">{{$t('设备id')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.deviceId"  :placeholder="$t('通用.输入.请输入')+$t('设备id')" />
      </a-form-model-item>
      <a-form-model-item prop="deviceName" >
        <span slot="label" >
          <a-tooltip>
            {{$t('设备名称')}}<template slot="title">{{$t('设备名称')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.deviceName" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('设备名称')" />
      </a-form-model-item>
      <a-form-model-item prop="triggerAttrId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('触发属性id')}}<template slot="title">{{$t('触发属性id')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.triggerAttrId"  :placeholder="$t('通用.输入.请输入')+$t('触发属性id')" />
      </a-form-model-item>
      <a-form-model-item prop="triggerAttrName" >
        <span slot="label" >
          <a-tooltip>
            {{$t('触发属性名称')}}<template slot="title">{{$t('触发属性名称')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.triggerAttrName" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('触发属性名称')" />
      </a-form-model-item>
      <a-form-model-item prop="deviceType" >
        <span slot="label" >
          <a-tooltip>
            {{$t('设备类型')}}<template slot="title">{{$t('设备类型')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="value" >
        <span slot="label" >
          <a-tooltip>
            {{$t('报警值')}}<template slot="title">{{$t('报警值')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.value"  :placeholder="$t('通用.输入.请输入')+$t('报警值')" />
      </a-form-model-item>
      <a-form-model-item prop="alarmTime" >
        <span slot="label" >
          <a-tooltip>
            {{$t('报警时间')}}<template slot="title">{{$t('报警时间')}}</template>
          </a-tooltip>
        </span>
        <a-date-picker style="width: 100%" v-model="form.alarmTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item prop="alarmContent" >
        <span slot="label" >
          <a-tooltip>
            {{$t('报警内容')}}<template slot="title">{{$t('报警内容')}}</template>
          </a-tooltip>
        </span>
        <editor v-model="form.alarmContent" />
      </a-form-model-item>
      <a-form-model-item prop="dealTime" >
        <span slot="label" >
          <a-tooltip>
            {{$t('处理时间')}}<template slot="title">{{$t('处理时间')}}</template>
          </a-tooltip>
        </span>
        <a-date-picker style="width: 100%" v-model="form.dealTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item prop="dealContent" >
        <span slot="label" >
          <a-tooltip>
            {{$t('处理内容')}}<template slot="title">{{$t('处理内容')}}</template>
          </a-tooltip>
        </span>
        <editor v-model="form.dealContent" />
      </a-form-model-item>
      <a-form-model-item prop="dealStatus" >
        <span slot="label" >
          <a-tooltip>
            {{$t('处理状态,0未处理，1已处理，2已恢复')}}<template slot="title">{{$t('处理状态,0未处理，1已处理，2已恢复')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="dealUserId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('处理人')}}<template slot="title">{{$t('处理人')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.dealUserId"  :placeholder="$t('通用.输入.请输入')+$t('处理人')" />
      </a-form-model-item>
      <a-form-model-item prop="deviceAttrUnit" >
        <span slot="label" >
          <a-tooltip>
            {{$t('设备属性单位')}}<template slot="title">{{$t('设备属性单位')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.deviceAttrUnit" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('设备属性单位')" />
      </a-form-model-item>
      <a-form-model-item prop="triggerCondition" >
        <span slot="label" >
          <a-tooltip>
            {{$t('触发条件')}}<template slot="title">{{$t('触发条件')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.triggerCondition" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('触发条件')" />
      </a-form-model-item>
      <a-form-model-item prop="sampleId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('设备模板Id')}}<template slot="title">{{$t('设备模板Id')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.sampleId"  :placeholder="$t('通用.输入.请输入')+$t('设备模板Id')" />
      </a-form-model-item>
      <a-form-model-item prop="operateInteger" >
        <span slot="label" >
          <a-tooltip>
            {{$t('按照报警信息处理模板，当前到什么位置 默认为0')}}<template slot="title">{{$t('按照报警信息处理模板，当前到什么位置 默认为0')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.operateInteger"  :placeholder="$t('通用.输入.请输入')+$t('按照报警信息处理模板，当前到什么位置 默认为0')" />
      </a-form-model-item>
      <a-form-model-item prop="alarmActivityId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('报警模板Id')}}<template slot="title">{{$t('报警模板Id')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.alarmActivityId"  :placeholder="$t('通用.输入.请输入')+$t('报警模板Id')" />
      </a-form-model-item>
      <a-form-model-item prop="bindType" >
        <span slot="label" >
          <a-tooltip>
            {{$t('1：船舶 2：港口')}}<template slot="title">{{$t('1：船舶 2：港口')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="portId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('船舶主键')}}<template slot="title">{{$t('船舶主键')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.portId"  :placeholder="$t('通用.输入.请输入')+$t('船舶主键')" />
      </a-form-model-item>
      <a-form-model-item prop="operate" >
        <span slot="label" >
          <a-tooltip>
            {{$t('是否需要处理')}}<template slot="title">{{$t('是否需要处理')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.operate"  :placeholder="$t('通用.输入.请输入')+$t('是否需要处理')" />
      </a-form-model-item>
      <a-form-model-item prop="shipId" >
        <span slot="label" >
          <a-tooltip>
            {{$t('船舶主键')}}<template slot="title">{{$t('船舶主键')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.shipId"  :placeholder="$t('通用.输入.请输入')+$t('船舶主键')" />
      </a-form-model-item>
      <a-form-model-item prop="remark" >
        <span slot="label" >
          <a-tooltip>
            {{$t('备注描述')}}<template slot="title">{{$t('备注描述')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.remark" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item prop="alarmLevel" >
        <span slot="label" >
          <a-tooltip>
            {{$t('报警等级')}}<template slot="title">{{$t('报警等级')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.alarmLevel" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('报警等级')" />
      </a-form-model-item>
      <a-form-model-item prop="alarmLevelType" >
        <span slot="label" >
          <a-tooltip>
            {{$t('报警等级 1 一级 2 二级 3 三级')}}<template slot="title">{{$t('报警等级 1 一级 2 二级 3 三级')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="deviceStatus" >
        <span slot="label" >
          <a-tooltip>
            {{$t('处理状态,0未处理，1已处理，2已恢复')}}<template slot="title">{{$t('处理状态,0未处理，1已处理，2已恢复')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-form-model-item prop="pictures" >
        <span slot="label" >
          <a-tooltip>
            {{$t('图片 ,隔开')}}<template slot="title">{{$t('图片 ,隔开')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.pictures" :placeholder="$t('通用.输入.请输入')" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item prop="videoUrl" >
        <span slot="label" >
          <a-tooltip>
            {{$t('视频地址')}}<template slot="title">{{$t('视频地址')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.videoUrl" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('视频地址')" />
      </a-form-model-item>
      <a-form-model-item prop="videoUrlPicture" >
        <span slot="label" >
          <a-tooltip>
            {{$t('视频封面')}}<template slot="title">{{$t('视频封面')}}</template>
          </a-tooltip>
        </span>
        <a-input v-model="form.videoUrlPicture" :maxLength="255" :placeholder="$t('通用.输入.请输入')+$t('视频封面')" />
      </a-form-model-item>
      <a-form-model-item prop="alarmStatus" >
        <span slot="label" >
          <a-tooltip>
            {{$t('处理类型 0:未读 1:已读 2.处理')}}<template slot="title">{{$t('处理类型 0:未读 1:已读 2.处理')}}</template>
          </a-tooltip>
        </span>
      </a-form-model-item>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl" />
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getDeviceAlarm, addDeviceAlarm, updateDeviceAlarm } from '@/api/alarm/deviceAlarm'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag/index.vue";
import {generateFilePath, uploadObject} from "@/api/tool/oss";

import Editor from '@/components/Editor'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
    Editor
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        triggetId: null,

        triggerName: null,

        projectId: null,

        triggerType: null,

        deviceId: null,

        deviceName: null,

        triggerAttrId: null,

        triggerAttrName: null,

        deviceType: null,

        value: null,

        alarmTime: null,

        alarmContent: null,

        dealTime: null,

        dealContent: null,

        dealStatus: 0,

        dealUserId: null,

        deviceAttrUnit: null,

        triggerCondition: null,

        sampleId: null,

        operateInteger: null,

        alarmActivityId: null,

        bindType: null,

        portId: null,

        operate: null,

        shipId: null,

        createTime: null,

        remark: null,

        alarmLevel: null,

        alarmLevelType: null,

        deviceStatus: 0,

        pictures: null,

        videoUrl: null,

        videoUrlPicture: null,

        alarmStatus: 0

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        triggetId: null,
        triggerName: null,
        projectId: null,
        triggerType: null,
        deviceId: null,
        deviceName: null,
        triggerAttrId: null,
        triggerAttrName: null,
        deviceType: null,
        value: null,
        alarmTime: null,
        alarmContent: null,
        dealTime: null,
        dealContent: null,
        dealStatus: 0,
        dealUserId: null,
        deviceAttrUnit: null,
        triggerCondition: null,
        sampleId: null,
        operateInteger: null,
        alarmActivityId: null,
        bindType: null,
        portId: null,
        operate: null,
        shipId: null,
        createTime: null,
        remark: null,
        alarmLevel: null,
        alarmLevelType: null,
        deviceStatus: 0,
        pictures: null,
        videoUrl: null,
        videoUrlPicture: null,
        alarmStatus: 0
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getDeviceAlarm({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateDeviceAlarm(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addDeviceAlarm(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
