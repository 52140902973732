import request from '@/utils/request'


// 查询设备报警记录列表
export function listDeviceAlarm(query) {
  return request({
    url: '/gather/gather-device-alarm/list',
    method: 'get',
    params: query
  })
}

// 查询设备报警记录分页
export function pageDeviceAlarm(query) {
  return request({
    url: '/gather/gather-device-alarm/page',
    method: 'get',
    params: query
  })
}

// 查询设备报警记录详细
export function getDeviceAlarm(data) {
  return request({
    url: '/gather/gather-device-alarm/detail',
    method: 'get',
    params: data
  })
}

// 新增设备报警记录
export function addDeviceAlarm(data) {
  return request({
    url: '/gather/gather-device-alarm/add',
    method: 'post',
    data: data
  })
}

// 修改设备报警记录
export function updateDeviceAlarm(data) {
  return request({
    url: '/gather/gather-device-alarm/edit',
    method: 'post',
    data: data
  })
}

// 删除设备报警记录
export function delDeviceAlarm(data) {
  return request({
    url: '/gather/gather-device-alarm/delete',
    method: 'post',
    data: data
  })
}
